import { ApplicationData } from "../ApplicationData";

function isSupportButtonDisabled(date) {
    // Get the current day of the week (0 for Sunday, 6 for Saturday)
    const day = date.getDay();
    
    // Get the current hour (0-23)
    const hour = date.getHours();

    const minutes = date.getMinutes();
    
    // Check if it's within business hours on weekdays
    if (day >= 1 && day <= 5 && hour >= 7 && (hour < 7 && minutes < 30)) {
        return false; // Button enabled on weekdays between 9AM and 9PM EST
    }
    
    // Check if it's within business hours on Saturday
    if (day === 6 && hour >= 9 && hour < 18) {
        return false; // Button enabled on Saturday between 9AM and 6PM EST
    }
    
    // All other cases, button is disabled
    return true;
}

function isAvailableSupportText(){
    let content = "Lun-Vie 9:00AM-7:30PM, Sáb 9:00AM-6:00PM EST"

    if(ApplicationData.get().user.lang !== "es"){
        content = "Mon-Fri 09:00-19:30, Sáb 09:00-18:00 EST"
    }

    return content;
}

export {
    isSupportButtonDisabled,
    isAvailableSupportText
}